















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
    name: 'CountrySupport'
})
export default class extends Vue {
    @Prop({default: ''}) countryName!: string

    @Prop({default: () => [] }) supportList!: Array<number>

    @Prop({default: ''}) imgPath!: string
}
